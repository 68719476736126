/* Flex stuff */
.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

.flex-col {
	flex-direction: column;
}

.flex-1 {
	flex: 1 1 auto;
}

.flex-shrink {
	flex-shrink: 1;
}

.flex-shrink-0 {
	flex-shrink: 0;
}

.flex-grow {
	flex-grow: 1;
}

.flex-wrap {
	flex-wrap: wrap;
}

.items-center {
	align-items: center;
}

.align-center {
	align-self: center;
}

.justify-center {
	justify-content: center;
}

.justify-end {
	justify-content: flex-end;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-between {
	justify-content: space-between;
}

/* The rest */

h1,
h2,
h3,
h4,
p {
	margin: unset;
}

.align-bottom {
	vertical-align: bottom;
}

.align-middle {
	vertical-align: middle;
}

.align-top {
	vertical-align: top;
}

.h-screen {
	height: 100vh;
}

.min-h-screen {
	min-height: 100vh;
}

.w-full {
	width: 100%;
}

.w-fit {
	width: fit-content;
}

.h-fit {
	height: fit-content;
}

.h-full {
	height: 100%;
}

.border {
	border: 1px solid #000;
}

.border_gray {
	border: 1px solid #dadada;
}

.border_white {
	border: 1px solid #fff;
}

.color_white {
	color: #fff;
}

.color_white_imp {
	color: #fff !important;
}

.color_black {
	color: #000;
}

.underline {
	text-decoration: underline;
}

.cursor-pointer {
	cursor: pointer;
}

.pointer-events-none {
	pointer-events: none;
}

.float_left {
	float: left;
}

.float_right {
	float: right;
}

.float_clear {
	clear: both;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.text_center {
	text-align: center;
}

.text_left {
	text-align: left;
}

.text_right {
	text-align: right;
}

.inline_block {
	display: inline-block;
}

.display_none {
	display: none;
}

.display_block {
	display: block;
}

.visibility_hidden {
	visibility: hidden;
}

.visibility_visible {
	visibility: visible;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.normal-case {
	text-transform: none;
}

.bold {
	font-weight: 600;
}

.whitespace-pre-line {
	white-space: pre-line;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.ml-auto {
	margin-left: auto;
}

.mt-auto {
	margin-top: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.myx-auto {
	margin: auto;
}

.ml-1 {
	margin-left: 0.25rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.ml-3 {
	margin-left: 0.75rem;
}

.mt-1 {
	margin-top: 0.25rem;
}

.mt-2 {
	margin-top: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.mb-1 {
	margin-bottom: 0.25rem;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.mb-4 {
	margin-bottom: 1rem;
}

.mb-8 {
	margin-bottom: 2rem;
}

.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.mt-3 {
	margin-top: 0.75rem;
}

.my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}

.m-4 {
	margin: 1rem;
}
.mt-4 {
	margin-top: 1rem;
}

.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.my-8 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.mt-6 {
	margin-top: 1.5rem;
}

.mt-8 {
	margin-top: 2rem;
}

.mt-10 {
	margin-top: 2.5rem;
}

.mt-12 {
	margin-top: 3rem;
}

.mt-16 {
	margin-top: 4rem;
}

.mt-24 {
	margin-top: 6rem;
}

.mt-32 {
	margin-top: 8rem;
}

.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.mr-1 {
	margin-right: 0.25rem;
}

.mr-2 {
	margin-right: 0.5rem;
}

.mr-4 {
	margin-right: 1rem;
}

.mr-8 {
	margin-right: 2rem;
}

.mr-12 {
	margin-right: 3rem;
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mx-8 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.ml-4 {
	margin-left: 1rem;
}

.ml-6 {
	margin-left: 1.5rem;
}

.ml-8 {
	margin-left: 2rem;
}

.ml-12 {
	margin-left: 3rem;
}

.px-1 {
	padding-left: 0.25rem;
	padding-right: 0.25rem;
}

.p-2 {
	padding: 0.5rem;
}

.pt-2 {
	padding-top: 0.5rem;
}

.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.p-4 {
	padding: 1rem;
}

.p-5 {
	padding: 1.25rem;
}

.pb-2 {
	padding-bottom: 0.5rem;
}

.pb-4 {
	padding-bottom: 1rem;
}

.pb-6 {
	padding-bottom: 1.5rem;
}

.pb-8 {
	padding-bottom: 2rem;
}

.pl-5 {
	padding-left: 1.25rem;
}

.pl-8 {
	padding-left: 2rem;
}

.pt-4 {
	padding-top: 1rem;
}

.pr-4 {
	padding-right: 1rem;
}

.pr-8 {
	padding-right: 2rem;
}

.py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.px-8 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.pt-8 {
	padding-top: 2rem;
}

.p-8 {
	padding: 2rem;
}
