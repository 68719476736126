.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    width: 100vw;
    max-width: 1440px;
    background-color: var(--light_blue);
    z-index: 1200;
}

.header_left {
    width: 80px;
}

.header_right {
    text-align: right;
    flex-shrink: 0;
    margin-right: 80px;
}

.header_breadcrumbs {
    text-align: left;
    flex-grow: 1;
}

.header_breadcrumbs_small {
    display: none;
}

.header_breadcrumbs_large {
    display: flex;
}

.header_breadcrumbs_name {
    font-weight: 600;
}

.header_filter_section {
    margin: 80px 0 -60px 80px;
}

@media only screen and (max-width: 768px) {
    .header_breadcrumbs_small {
        display: flex;
    }

    .header_breadcrumbs_large {
        display: none;
    }

    .header_left {
        display: none;
    }
}
