h1 {
	font-size: 40px;
	font-weight: 600;
	line-height: 56px;
}

h2 {
	font-size: 25px;
	font-weight: 600;
	line-height: 37px;
}

h3, .h3 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
}

h4, .h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
}

p {
	font-size: 16px;
	line-height: 24px;
}

p.smaller {
	font-size: 14px;
	line-height: 22px;
}

.data_table {
	border-collapse: collapse;
}

.data_table th {
	padding: 8px 16px;
	text-align: left;
	font-weight: 600;
}

.data_table td {
	padding: 8px 16px;
}

.data_table.lines tr {
	border-bottom: 1px solid #ccc;
}

.h65 {
	height: 65px;
}

.col_bg {
	background-color: #f6faef;
}

.bolder {
	font-weight: 700;
}

/* variables */
:root {
	/*--light_blue: #f3f6ff;*/
	--light_green: #f6faee;
	--light_gray: #efefef;
	--lighter_gray: #f9f8f7;
	--border_gray: #e5e5e9;
	--dark_gray: #4c4c67;
	--ion-background-color: var(--light_blue);
	--dark_blue: #000026;
	--blue: #94ABFF;
	--light_blue: #e8efff;
	--warn: #ff405e;
	--light_warn: #ffbbbb;
}

/* Standard selectors */

body {
	font-family: "Garnett", "sans-serif";
	padding: 0;
	margin: 0;
}

ion-toggle.toggle_small {
	height: 20px;
	width: 36px;
	--handle-width: 16px;
	--handle-height: 16px;
	--background-checked: var(--blue);
}

ion-segment {
	border-radius: 5px;
}

ion-segment.light {
	--background: var(--light_gray);
}

.light > ion-segment-button {
	--indicator-color: #ffffff;
}

ion-segment-button.ios {
	--padding-start: 34px;
	--padding-end: 34px;
}

ion-segment-button::part(indicator-background) {
	border-radius: 5px;
}

.color_warn {
	color: var(--warn);
}

.color_dark_gray {
	color: var(--dark_gray);
}

.light_green {
	color: var(--light_green);
}

.bg_light_green {
	background-color: var(--light_green);
}

.bg_lighter_gray {
	background-color: var(--lighter_gray);
}

.bg_border_gray {
	background-color: var(--border_gray);
}

.bg_blue {
	background-color: var(--blue);
}

.bg_light_blue {
	background-color: var(--light_blue);
}

.bg_pink_blue {
	background: linear-gradient(90deg, rgba(255, 204, 200, 1) 0%, rgba(188, 128, 240, 1) 100%);
}

.custom_h1 {
	font-size: 40px;
	font-weight: 600;
	line-height: 56px;
}

.custom_h2 {
	font-size: 25px;
	font-weight: 600;
	line-height: 37px;
}

#mouse_over {
	position: fixed;
	display: flex;
	align-items: center;
	z-index: 1001;
	pointer-events: none;
	filter: drop-shadow(2px 4px 8px #ccc);
}

.mouse_arrow {
	width: 0;
	height: 0;
	border-top: 18px solid transparent;
	border-bottom: 18px solid transparent;
	border-right:18px solid #ffffff;
}

.page_content {
	margin: 80px;
}

.page_section {
	background-color: #fff;
	border-radius: 4px;
	max-width: 1200px;
	padding: 40px;
	color: #000026;
}

.page_title_bar {
	background-color: #C1C1C1;
	height: 1px;
	width: 100%;
	margin: 10px 0;
}

.page_intro_text {
	max-width: 800px;
}

.page_half_section {
	width: calc(50vw - 40px);
	max-width: 640px;
}

.checkbox {
	width: 22px;
	height: 22px;
	border: 1pt solid #000;
	border-radius: 4px;
}

.box_gray {
	border: 1px solid var(--border_gray);
	border-radius: 5px;
	padding: 20px;
}

.border_bottom_gray {
	border-bottom: 1px solid var(--border_gray);
}

.meeting_status_box {
	padding: 16px;
	background-color: var(--light_green);
}

.meeting_status_box.warn {
	background-color: var(--light_warn);
}

.checkbox.checked {
	background-image: url(/assets/icon/checkmark-sharp.svg);
}

.icon_small {
	width: 20px;
	height: 20px;
}

.icon_smaller {
	width: 12px;
	height: 12px;
}

.icon_medium {
	width: 30px;
	height: 30px;
}

.icon_h2 {
	width: 25px;
	height: 25px;
}


.icon_large {
	width: 50px;
	height: 50px;
}

.bar_height {
	height: 12px;
}

ul li {
	padding-bottom: 6px;
}

.bg_white {
	background-color: #fff;
}

.white {
	color: #fff;
}

/* Containers */
.top_margin {
	margin-top: 20px;
}

.right_margin {
	margin-right: 20px;
}

.left_margin {
	margin-left: 20px;
}

.bottom_margin {
	margin-bottom: 20px;
}

.r_left_margin {
	margin-left: 20px;
}

.r_right_margin {
	margin-right: 20px;
}

.r_top_margin {
	margin-top: 0;
}

.round {
	border-radius: 50%;
}

.r-flex {
	display: flex;
}

.font_small {
	font-size: 14px;
}

.font_smaller {
	font-size: 12px;
}

/* elements */
.link_text {
	text-decoration: underline;
	cursor: pointer;
}

.link_text:hover {
	color: #00f;
}

.border_black {
	border: 1px solid #000;
}

.scroll_y {
	overflow-y: scroll;
}

.scroll_y::-webkit-scrollbar {
	display: none;
}

.modal_small {
	--height: 400px;
	--width: 300px;
	--border-radius: 10px;
}

.modal_medium {
	--height: 500px;
	--width: min(400px, 94vw);
	--border-radius: 10px;
}

.border_radius {
	border-radius: 5px;
}

.border_radius6 {
	border-radius: 6px;
}

.r-ml-4 {
	margin-left: 2rem;
}

.r-ml-8 {
	margin-left: 2rem;
}

.r-ml-12 {
	margin-left: 3rem;
}

.page_bar {
	background-color: #e5e5e9;
	height: 1px;
}

.border_left {
	border-left: 1px solid #e5e5e9;
}

/* Bar charts */
.bar_background {
	width: 100%;
	height: 30px;
	border-radius: 15px;
	background-color: #e5e5e9;
	position: relative;
}

.bar_background.small {
	height: 15px;
	border-radius: 8px;
}

.bar_animated {
	height: 30px;
	width: 0;
	position: absolute;
	z-index: 5;
	border-radius: 15px;
	transition: width 1s;
}

.bar_animated.small {
	height: 15px;
	border-radius: 8px 0 0 8px;
}

.bar_background_standing {
  height: 300px;
  width: 40px;
  position: relative;
  margin: auto;
}

.bar_animated_standing {
  width: 40px;
  height: 0;
  transition: height 1s;
  position: absolute;
  bottom: 0;
}

.barchart_bar {
	border: 1px solid #c4c4c4;
	width: calc(50vw - 40px);
	max-width: 640px;
}

/* Buttons */
.button {
	font-size: 14px;
	padding: 13px 18px;
	border-radius: 0 5px 0 5px;
	color: #000026;
	border: 1px solid #D1F19E;
	background-color: #D1F19E;
	cursor: pointer;
}

.button:hover {
	border: 1px solid #ECF8DD;
	background-color: #ECF8DD;
}

.button.blue {
	border: 1px solid #94ABFF;
	background-color: #94ABFF;
}

.button.blue:hover {
	border: 1px solid #E8EFFF;
	background-color: #E8EFFF;
}

.button.darkblue {
	color: #fff;
	border: 1px solid #fff;
	background-color: #000026;
}

.button.darkblue:hover {
	color: #000026;
	border: 1px solid #fff;
	background-color: #fff;
}

.button.red {
	color: #fff;
	border: 1px solid #FF405E;
	background-color: #FF405E;
}

.button.red:hover {
	color: #000026;
	border: 1px solid #FFC4CE;
	background-color: #FFC4CE;
}

.button.white {
	color: #000026;
	border: 1px solid #000026;
	background-color: #fff;
}

.button.white:hover {
	color: #fff;
	border: 1px solid #000026;
	background-color: #000026;
}

.button_disabled {
	opacity: 0.5;
	pointer-events: none;
}

.mouse_over_inner {
	color: #000;
	background-color: #fff;
	height: fit-content;
	min-width: 220px;
	border-radius: 5px;
	z-index: 2001;
	padding: 16px;
}

.mouse_over {
	position: absolute;
	display: flex;
	align-items: center;
	visibility: hidden;
	z-index: 1001;
	pointer-events: none;
	filter: drop-shadow(2px 4px 8px #ccc);
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 30px;
		line-height: 40px;
	}

	h2 {
		font-size: 22px;
		line-height: 33px;
	}

	.r-flex {
		display: block;
	}

	.r-ml-4,
	.r-ml-12 {
		margin-left: 0;
	}

	.top_margin,
	.r_top_margin {
		margin-top: 15px;
	}

	.right_margin {
		margin-right: 15px;
	}

	.left_margin {
		margin-left: 15px;
	}

	.bottom_margin {
		margin-bottom: 15px;
	}

	.r_left_margin {
		margin-left: 0px;
	}

	.r_right_margin {
		margin-right: 0px;
	}

	.page_content {
		margin: 80px 16px;
	}

	.page_half_section {
		width: 100%;
	}

	.barchart_bar {
		width: calc(100vw - 70px);
	}

	.page_section {
		padding: 20px;
	}

	.page_section_direction {
		flex-direction: column;
	}
}
