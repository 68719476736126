.heatmap_barchart_position {
  padding-left: 25px;
}

.heatmap_barchart_titles {
  width: calc(50vw - 40px);
  padding: 0 25px 0 15px;
  max-width: 640px;
}

.heatmap_point_scale {
  padding: 20px 40px 40px 20px;
  width: fit-content;
  height: fit-content;
}

.heatmap_summary_bar {
  background-color: #000;
  height: 1px;
  width: 100%;
  margin: 25px 0;
}

.heatmap_spread {
  border: dashed #000026 2px ;
  border-radius: 10px;
  height: 17px;
  margin-top: -18px;
  z-index: 200;
}

.heatmap_svg_height {
  height: 94px;
}

.heatmap_info_width {
  width: 289px;
}

.heatmap_col_2 {
  width: 385px;
}

.heatmap_col_2_small {
  width: 380px;
}

.heatmap_svg_height.large {
  height: 140px;
}

.heatmap_diagram {
  width: 340px;
  height: 340px;
  padding: 20px;
}

table.heatmap_table_lines {
  border-collapse: collapse;
}

.heatmap_table_lines td {
  padding: 10px 30px 10px 0;
}

.heatmap_table_lines.wide td {
  padding: 10px 50px 10px 0;
}

/* Media query */
@media screen and (max-width: 768px) {
  .heatmap_barchart_titles {
		width: calc(100vw - 70px);
	}
}
